import { API } from 'aws-amplify';
import {
  USER_INFO,
  WORKSPACE_PERMISSION,
  WORKSPACE_FIELDS,
  WORKSPACE_PERMISSION_CONNECTION,
  DISABLED_WORKSPACES_IDS, WORKSPACE_TYPE, WORKSPACE_PAGE, WORKSPACE_PAGE_CONNECTION,
} from './fragments';
import requestBuilder from '@/services/graphql/requestBuilder';
import { GRAPHQL_TYPES } from '@/constants/cores';
export default {
  getWorkspacesList(variables) {
    return new requestBuilder({
      requestName: 'listMyWorkspaces',
      requestVariables: {
        nextToken: 'String',
        limit: 'Int',
      },
      response: WORKSPACE_PERMISSION_CONNECTION,
      variables,
    }).processRequest();
  },
  getWorkspace(variables) {
    return new requestBuilder({
      requestName: 'getWorkspace',
      requestVariables: {
        workspaceId: 'String!',
      },
      response: WORKSPACE_TYPE,
      variables,
    }).processRequest();
  },
  createWorkspacePage(variables) {
    return new requestBuilder({
      requestName: 'createWorkspacePage',
      requestType: GRAPHQL_TYPES.MUTATION,
      requestVariables: {
        workspaceId: 'String!',
      },
      response: WORKSPACE_PAGE,
      variables,
    }).processRequest();
  },
  createWorkspacePageV2(variables) {
    return new requestBuilder({
      requestName: 'createWorkspacePageV2',
      requestType: GRAPHQL_TYPES.MUTATION,
      requestVariables: {
        workspaceId: 'String!',
      },
      response: WORKSPACE_PAGE,
      variables,
    }).processRequest();
  },
  deleteWorkspacePage(variables) {
    return new requestBuilder({
      requestName: 'deleteWorkspacePage',
      requestType: GRAPHQL_TYPES.MUTATION,
      requestVariables: {
        workspaceId: 'String!',
        pageId: 'String!',
      },
      response: WORKSPACE_PAGE,
      variables,
    }).processRequest();
  },
  listWorkspacePages(variables) {
    return new requestBuilder({
      requestName: 'listWorkspacePages',
      requestVariables: {
        workspaceId: 'String',
        limit: 'Int',
        nextToken: 'String',
        pageId: 'String',
        accessToken: 'String',
      },
      response: WORKSPACE_PAGE_CONNECTION,
      variables,
      authMode: 'AWS_IAM',
    }).processRequest();
  },
  getWorkspacesListForFollowing(variables) {
    return new requestBuilder({
      requestName: 'listWorkspacesForFollowing',
      requestVariables: {
        limit: 'Int',
        nextToken: 'String',
      },
      response: WORKSPACE_PERMISSION_CONNECTION,
      variables,
    }).processRequest();
  },
  listWorkspacesWithCollection(variables) {
    return new requestBuilder({
      requestName: 'listWorkspacesWithCollection',
      requestVariables: {
        collectionId: 'String!',
        limit: 'Int',
        nextToken: 'String',
      },
      response: DISABLED_WORKSPACES_IDS,
      variables,
    }).processRequest();
  },
  createWorkspaceV2(variables) {
    return new requestBuilder({
      requestName: 'createWorkspaceV2',
      requestType: GRAPHQL_TYPES.MUTATION,
      requestVariables: {
        name: 'String!',
        description: 'String',
        logo: 'String',
      },
      response: WORKSPACE_PERMISSION,
      variables,
    }).processRequest();
  },
  updateWorkspace(variables) {
    return new requestBuilder({
      requestName: 'updateWorkspaceInfo',
      requestType: GRAPHQL_TYPES.MUTATION,
      requestVariables: {
        workspaceId: 'String!',
        name: 'String',
        description: 'String',
        logo: 'String',
      },
      response: WORKSPACE_FIELDS,
      variables,
    }).processRequest();
  },
  listUsersInWorkspace(variables) {
    return new requestBuilder(
      {
        requestName: 'listUsersInWorkspace',
        requestVariables: {
          workspaceId: 'String!',
          membership: 'WorkspaceMembership',
          nextToken: 'String',
        },
        response: `
          data {
              userInfo ${USER_INFO}
              type
            }
          nextToken
        `,
        variables,
      },
    ).processRequest();
  },
  updateWorkspaceMembership(variables) {
    return new requestBuilder(
      {
        requestName: 'updateWorkspaceMembership',
        requestType: GRAPHQL_TYPES.MUTATION,
        requestVariables: {
          workspaceId: 'String!',
          sharingUserId: 'String!',
          permissionType: 'WorkspaceRole!',
        },
        response: `
          ${WORKSPACE_PERMISSION}
          userInfo ${USER_INFO}
        `,
        variables,
      },
    ).processRequest();
  },
  deleteWorkspaceMembership(variables) {
    return new requestBuilder({
      requestName: 'deleteWorkspaceMembership',
      requestType: GRAPHQL_TYPES.MUTATION,
      requestVariables: {
        workspaceId: 'String!',
        userId: 'String!',
      },
      response: `
        ${WORKSPACE_PERMISSION}
        userInfo ${USER_INFO}
      `,
      variables,
    }).processRequest();
  },
  /**
   * @deprecated use instead inviteToWorkspace
   * @param variables
   * @returns {Promise<GraphQLResult> | Observable<object>}
   */
  shareWorkspace(variables) {
    const query = `
      mutation shareWorkspace(
        $workspaceId: String!,
        $sharingUserId: String!,
        $permissionType: SharingPermissionType!,
      ) {
      response: shareWorkspace (
        workspaceId: $workspaceId
        sharingUserId: $sharingUserId
        permissionType: $permissionType) {
          ${WORKSPACE_PERMISSION}
          userInfo ${USER_INFO}
        }
      }
    `;
    return API.graphql({
      query,
      variables,
    });
  },
  /**
   * Query to invite users
   * @param variables
   * @returns {Promise<GraphQLResult> | Observable<object>}
   */
  inviteToWorkspace(variables) {
    return new requestBuilder({
      requestName: 'inviteToWorkspace',
      requestType: GRAPHQL_TYPES.MUTATION,
      requestVariables: {
        workspaceId: 'String!',
        emailMessage: 'String',
        members: '[WorkspaceMember]',
      },
      variables,
    }).processRequest();
  },
  /**
   * Subscription for inviteToWorkspace request
   * @param variables
   * @returns {Promise<GraphQLResult> | Observable<object>}
   */
  invitationReport(variables) {
    return new requestBuilder({
      requestName: 'subscribeInviteReport',
      requestType: GRAPHQL_TYPES.SUBSCRIPTION,
      requestVariables: {
        requestId: 'String!',
      },
      response:
        ` requestId
          error
          message
        `,
      variables,
    }).processRequest();
  },
  isUserNeedPremium(variables) {
    const query = `
      mutation isUserNeedPremium(
        $workspaceId: String!,
        $resourceType: ResourceType,
        $resourceId: String,
      ) {
      response: isUserNeedPremium (
        workspaceId: $workspaceId
        resourceType: $resourceType
        resourceId: $resourceId
        )
      }
    `;
    return API.graphql({
      query,
      variables,
    });
  },
};
