<template>
  <InteractiveTooltip
    content-class="btn-tooltip"
    top
    :nudge-top="$vuetify.breakpoint.lgAndUp ? 80 : -105">
    <template v-slot:activator="{ on }">
      <div
        v-if="showAIChat"
        data-airops-widget="36faca9b-4fa4-4c98-877f-225e9488abea"
        data-airops-init="true"
        v-on="canUseAiChat ? '' : on" />
    </template>
    <span>
      To access Sourcery AI, upgrade your workspace
      <router-link :to="getFullLinkForWorkspaces('workspace-payment')">here</router-link>
    </span>
  </InteractiveTooltip>
</template>

<script>
import { mapGetters } from 'vuex';
import InteractiveTooltip from '@/components/App/AppInteractiveTooltip';
import { getFullLinkForWorkspaces } from '@/utils';

export default {
  name: 'AppAIChat',
  components: {
    InteractiveTooltip,
  },
  computed: {
    ...mapGetters('FeatureFlags', ['showAIChat']),
    ...mapGetters('Workspace', ['isPremiumWorkspace']),
    ...mapGetters('FeatureFlags', ['corePackageUpdates']),
    canUseAiChat() {
      if (!this.corePackageUpdates) return false;
      return this.isPremiumWorkspace;
    },
  },
  watch: {
    canUseAiChat: {
      handler(newValue, oldValue) {
        if (newValue && !oldValue) {
          this.enableAIChatButton();
        } else if (!newValue && oldValue) {
          this.disableAIChatButton();
        }
      },

    },
  },
  created() {
    if (this.showAIChat) this.loadExternalResources();
  },
  methods: {
    getFullLinkForWorkspaces,

    loadExternalResources() {
      // Load the external script file
      const script = document.createElement('script');
      script.src = 'https://assets.airops.com/widget/index.js';
      script.async = true;

      // update css styles after script is loaded
      script.onload = () => {
        this.updateStyles();
        if (!this.canUseAiChat) {
          this.disableAIChatButton();
        }
      };

      document.head.appendChild(script);
    },
    updateStyles() {
      this.$nextTick();
      const host = document.querySelectorAll('[data-airops-widget="36faca9b-4fa4-4c98-877f-225e9488abea"]')[0];

      // get the shadow element
      const shadowDom = host.shadowRoot;

      const shadowStyle = document.createElement('style');
      shadowStyle.textContent = `
        .ao-h-\\[740px\\] {
          height: 580px;
        }
        .ao-w-\\[400px\\] {
          width: 300px;
          @media (min-width: 992px) {
            width: 400px;
          }
        }
        .ao-bg-primary, .ao-bg-user-message {
          background: var(--ao-primary);
        }
        .ao-bottom-12 {
          bottom: calc(50% - 50px);
          right: 12px;
          @media (min-width: 992px) {
            bottom: 20px;
            right: 20px;
          }
        }
        .ao-h-12.ao-w-12 {
          height: 45px;
          width: 45px;

          @media (min-width: 992px) {
            height: 65px;
            width: 65px;
          }

          svg {
            height: 20px;
            width: 20px;

            @media (min-width: 992px) {
              height: 30px;
              width: 30px;
            }
          }
        }
        .ao-absolute.ao-bottom-16.ao-right-0 {
          position: fixed;
          bottom: 6.5rem;
          right: 4rem;

          @media (min-width: 992px) {
            position: absolute;
            bottom: 4.5rem;
            right: 0;
          }
        }
      `;
      // add custom styles to it
      shadowDom.appendChild(shadowStyle);
    },
    // Required to WAIT for the first load because it takes a bit for the ai-widget to mount.
    // Then we change styles after it does by watching for mutation. Otherwise if already mounted, we edit directly.
    disableAIChatButton(firstLoad) {
      const host = document.querySelector('[data-airops-widget="36faca9b-4fa4-4c98-877f-225e9488abea"]');
      if (!host) {
        setTimeout(() => this.enableAiChatButton(true), 500);
        return;
      }
      const shadowRoot = host.shadowRoot;

      const disableButton = () => {
        const buttons = shadowRoot.querySelectorAll('button.ao-absolute[type="button"]');
        for (const btn of buttons) {
          btn.disabled = true;
          btn.style.cursor = 'not-allowed';
          btn.style.opacity = '0.25';
        }
      };

      if (firstLoad) {
        const observer = new MutationObserver(() => {
          disableButton();
        });
        observer.observe(shadowRoot, {
          childList: true,
          subtree: true,
        });
      } else {
        disableButton();
      }
    },
    enableAIChatButton(firstLoad) {
      const host = document.querySelector('[data-airops-widget="36faca9b-4fa4-4c98-877f-225e9488abea"]');
      if (!host) {
        setTimeout(() => this.enableAiChatButton(true), 500);
        return;
      }
      const shadowRoot = host.shadowRoot;

      const enableButton = () => {
        const buttons = shadowRoot.querySelectorAll('button.ao-absolute[type="button"]');
        for (const btn of buttons) {
          btn.disabled = false;
          btn.style.cursor = 'pointer';
          btn.style.opacity = '1';
        }
      };

      if (firstLoad) {
        const observer = new MutationObserver(() => {
          enableButton();
        });
        observer.observe(shadowRoot, {
          childList: true,
          subtree: true,
        });
      } else {
        enableButton();
      }
    },
  },
};
</script>

<style lang="scss">
div[data-airops-widget] {
  --ao-primary: var(--v-black-base);
  --ao-user-message: var(--v-black-base);
  z-index: 99;
}
</style>