var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"share-autocomplete"},[_c('p',{staticClass:"small-p mb-0 share-autocomplete__invite-to-message"},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.showInviteMessage),expression:"showInviteMessage"}]},[_vm._v(" "+_vm._s(_vm.inviteMessage)+" ")])]),_c('v-combobox',{ref:"multyCombobox",staticClass:"v-autocomplete__share-groups autocomplete-wrapper",attrs:{"disabled":_vm.disabled,"items":_vm.users,"menu-props":{
      maxHeight:160
    },"attach":_vm.attach,"filled":"","chips":"","dense":"","deletable-chips":"","placeholder":_vm.placeholder,"multiple":"","error":!!_vm.selectErrors,"autocomplete":"off","search-input":_vm.search,"no-filter":"","hide-details":"","hide-no-data":"","hide-selected":"","item-value":"id","item-text":"email","return-object":""},on:{"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event},"change":_vm.change,"blur":_vm.onBlur},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
    var selected = ref.selected;
    var attrs = ref.attrs;
    var item = ref.item;
    var select = ref.select;
return [_c('v-chip',_vm._b({class:[_vm.higlightUsers && (item.paymentStatus ? 'v-chip__paid-user' : 'v-chip__free-user')],attrs:{"input-value":selected,"close":"","close-icon":"mdi-close","color":"#fff"},on:{"dblclick":function($event){return _vm.copyToClipBoard(item)},"click":select,"click:close":function($event){return _vm.remove(item)}}},'v-chip',attrs,false),[(!_vm.isValidEmail(item))?_c('v-icon',{attrs:{"color":"red","small":""}},[_vm._v(" mdi-alert ")]):_c('AvatarImage',{attrs:{"avatar":_vm.avatars[item.picture],"avatar-key":item.picture,"color":typeof item === 'object' && item.id ? item.id : 'blue',"size":20,"position":"left"}}),(typeof item === 'object')?_c('p',{staticClass:"small-p mb-0",staticStyle:{"color":"#515E61"}},[_vm._v(" "+_vm._s(item.name)+" ")]):_c('p',{staticClass:"small-p mb-0",staticStyle:{"color":"#515E61"}},[_vm._v(" "+_vm._s(item)+" ")])],1)]}},{key:"item",fn:function(ref){
    var item = ref.item;
return [(typeof item !== 'object')?[_c('v-list-item-content',{domProps:{"innerText":item}})]:_c('UserListItem',{attrs:{"item":item,"avatar":_vm.avatars[item.picture]}})]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }