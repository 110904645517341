<script>
import { VTooltip } from 'vuetify/lib';

/**
 * v-tooltip that allows it to STAY open if you move your mouse over it
 * - Allow interactivity like links.
 * - Pass in interactive=true (allows cleaner turning off to a "normal" tooltip
 *   (if interactive=false, then behaves like a normal tooltip [can't mouseover for link])
 */
export default {
  extends: VTooltip,
  props: {
    interactive: {
      type: Boolean,
      default: true,
    },
    closeDelay: {
      type: [Number, String],
      default: 300,
    },
  },
  computed: {
    calculatedLeft() {
      const originalValue = VTooltip.options.computed.calculatedLeft.call(this);
      if (!this.interactive) return originalValue;
      const { left, right } = this;
      let value = parseInt(originalValue);
      if (left || right) {
        value += right ? -10 : 10;
      }
      return `${value}px`;
    },
    calculatedTop() {
      const originalValue = VTooltip.options.computed.calculatedTop.call(this);
      if (!this.interactive) return originalValue;
      const { top, bottom } = this;
      let value = parseInt(originalValue);
      if (top || bottom) {
        value += bottom ? -10 : 10;
      }
      return `${value}px`;
    },
    styles() {
      const originalValue = VTooltip.options.computed.styles.call(this);
      if (!this.interactive) return originalValue;
      const { top, bottom, left, right } = this;
      let paddingDirection;
      if (bottom) paddingDirection = 'top';
      else if (top) paddingDirection = 'bottom';
      else if (right) paddingDirection = 'left';
      else if (left) paddingDirection = 'right';
      return {
        ...originalValue,
        [`padding-${paddingDirection}`]: `${10}px`,
      };
    },
  },
  methods: {
    onTooltipMouseenter(e) {
      if (this.interactive) {
        this.clearDelay();
        this.isActive = true;
      }
      this.$emit('tooltip:mouseenter', e);
    },
    onTooltipMouseleave(e) {
      if (this.interactive) {
        this.clearDelay();
        this.runDelay('close');
      }
      this.$emit('tooltip:mouseleave', e);
    },
    genContent() {
      const content = this.$createElement('div', this.setBackgroundColor(this.color, {
        style: this.contentStyles,
        staticClass: 'v-tooltip__content',
        class: {
          [this.contentClass]: true,
          menuable__content__active: this.isActive,
        },
      }), this.getContentSlot());
      return this.$createElement('div', {
        style: this.styles,
        attrs: this.getScopeIdAttrs(),
        class: {
          'v-tooltip__wrapper': true,
          'v-tooltip__wrapper--fixed': this.activatorFixed,
        },
        directives: [{
          name: 'show',
          value: this.isContentActive,
        }],
        on: {
          mouseenter: this.onTooltipMouseenter,
          mouseleave: this.onTooltipMouseleave,
        },
        ref: 'content',
      }, [content]);
    },
    genActivatorListeners() {
      const listeners = VTooltip.options.methods.genActivatorListeners.call(this);

      if (this.interactive) {
        if (listeners.mouseenter) {
          listeners.mouseenter = (e) => {
            this.getActivator(e);
            this.clearDelay();
            if (!this.isActive) {
              this.runDelay('open');
            }
          };
        }
      }

      return listeners;
    },
  },
};
</script>

<style lang="scss">
.v-tooltip__wrapper {
    position: absolute;
    &--fixed {
        position: fixed;
    }
    .v-tooltip__content {
        position: static;
    }
}
.v-tooltip__content {
  pointer-events: auto;
}
</style>
